<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="cars"

      :options.sync="options"
      :server-items-length="carsTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>

      <template v-slot:item.active="{ item }">
        <v-btn
          v-can.disable="'update_cars'"
          depressed
          data-toggle="tooltip"
          data-placement="top"
          title="تغيير الحالة"
          :color="item.active ? 'success' : 'error'"
          rounded
          small
          outlined
          @click="toggleActive(item)"
        >
          {{ item.active ? 'فعالة' : 'غير فعالة' }}
          <v-icon
            dark
            right
          >
            {{ item.active ? 'mdi-power' : 'mdi-power-off' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'
import { getFilters, getOptions } from '@/components/dataTables/helper'
import { ObjectToQuery } from '@/plugins/helper'

export default {
  name: 'Cars',
  components: { DatatableHeader },
  data: () => ({
    translationKey: 'pages.cars',
    filters: false,
    cars: [],
    carsTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'number',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'city',
        value: 'city',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'type',
        value: 'type',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'model',
        value: 'model',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'owner',
        value: 'owner',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'color',
        value: 'color',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'toggle',
        value: 'active',
        align: 'center',
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
    ],
  }),
  setup() {
  },

  async mounted() {
    this.options = getOptions(this.$route, this.options);
    await this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filter: 'deleted',
        filters,
      })
      const response = await axios.get(`/cars/dt?${query}`);
      this.cars = response.data.data.data
      this.carsTotal = response.data.data.total
      this.loading = false
    },

    $(key, params = []) {
      return this.$t(`${this.translationKey}.${key}`, params);
    },
  },

}
</script>
